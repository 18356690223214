<template>
  <div style="color: #666; font-size: 14px">

    <el-row :gutter="24" style="margin-top: 10px">
      <el-col :span="14">
        <el-card style="width: 100%">
          <p>
            您好，尊敬的管理员
          </p>
          <p style="margin-top: 5px">
            欢迎使用【鲨鱼票务】系统
          </p>
          <el-divider />
        </el-card>
      </el-col>

    </el-row>

  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {

    };
  },
};
</script>

<style>

</style>

